import { useCallback, useEffect, useState, KeyboardEvent } from "react";
import {
    sendPasswordResetEmail,
    signInWithCustomToken,
    signInWithEmailAndPassword,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { toast } from "sonner";

import api from "src/api/rest";
import { firebaseAuth } from "#app/firebase";
import { sendError } from "src/utils/errors";
import { EmailVerification } from "#email-verification";
import { Button } from "src/@/components/ui/button";
import { Input } from "src/@/components/ui/input";
import { Card } from "src/@/components/ui/card";
import { cn } from "src/@/lib/utils";

const _verifyUser = async (phoneNumber: string) => {
    const code = prompt(
        "We just sent you a verification code! Please enter it here:",
    );

    if (!code) {
        return;
    }

    try {
        const verifyPhoneResponse = await api.auth.verify(
            phoneNumber,
            code,
            false, // Do not create a new user if one does not exist
        );
        const { customAuthToken, user } = verifyPhoneResponse.data;
        if (!user) {
            toast.error("No user available");
            return;
        }
        await signInWithCustomToken(firebaseAuth, customAuthToken);
    } catch (err) {
        toast.error("Invalid verification code");
    }
};

const _promptPhoneNumber = async () => {
    const phoneNumber = prompt("Enter phone number here 📲");

    if (!phoneNumber) {
        return;
    }

    try {
        const response = await api.auth.sendVerification(phoneNumber);
        if (response?.status !== 200) {
            toast.error("Invalid phone number");
            return;
        }
        void _verifyUser(phoneNumber);
    } catch (err) {
        toast.error("Invalid phone number");
    }
};

type Props = {
    showEmailVerification: boolean;
    setHasVerifiedEmail: (hasVerifiedEmail: boolean) => void;
};

const SignIn = ({ showEmailVerification, setHasVerifiedEmail }: Props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const username = query.get("username");
        const password = query.get("password");

        if (username && password) {
            setEmail(username);
            setPassword(password);
        }
    }, []);

    const login = useCallback(() => {
        if (!email || !password) {
            toast.error("Please fill in all fields.");
            return;
        }

        signInWithEmailAndPassword(firebaseAuth, email.trim(), password).catch(
            (err: Error | FirebaseError) => {
                sendError(err);

                const errorMessage = ((e: Error | FirebaseError) => {
                    if (e instanceof FirebaseError && e.code) {
                        // Translate Firebase error message into something more
                        // user-friendly.
                        switch (e.code) {
                            case "auth/invalid-email":
                                return "The email address is formatted incorrectly.";
                            case "auth/user-disabled":
                                return "This account has been disabled. Please contact Snackpass support for more information.";
                            case "auth/user-not-found":
                                return "There is no account associated with the provided credentials.";
                            case "auth/wrong-password":
                                return "There is no account associated with the provided credentials.";
                            default:
                                return e.message;
                        }
                    } else {
                        return e.message;
                    }
                })(err);
                toast.error(errorMessage);
            },
        );
    }, [email, password]);

    const resetPassword = async () => {
        if (!email) {
            toast.error("Email required", {
                description:
                    "Please input your account's email address to reset its password.",
            });
            return;
        }

        await sendPasswordResetEmail(firebaseAuth, email)
            .then(async () =>
                toast.success("Email sent", {
                    description:
                        "An email has been sent with a link to reset your password.",
                }),
            )
            .catch(async (err: FirebaseError) => {
                sendError(err);
                toast.error("Email could not be sent", {
                    description: err.message,
                });
            });
    };

    const onEnter = useCallback(
        (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                login();
            }
        },
        [login],
    );

    return (
        <div className="flex min-h-screen items-center justify-center p-4">
            <Card className="w-full max-w-md space-y-8 p-8">
                <img
                    src="/snackpass-logo.svg"
                    alt="Snackpass Logo"
                    className={cn("mx-auto h-12 w-auto", "dark:invert")}
                />
                {showEmailVerification ? (
                    <EmailVerification
                        setHasVerifiedEmail={setHasVerifiedEmail}
                    />
                ) : (
                    <div className="space-y-6">
                        <h1 className="text-center text-2xl font-semibold">
                            Partner Dashboard
                        </h1>

                        <Button
                            variant="outline"
                            onClick={_promptPhoneNumber}
                            className="w-full"
                        >
                            Sign In With Phone Number
                        </Button>

                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <span className="w-full border-t" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="bg-background px-2 text-muted-foreground">
                                    or
                                </span>
                            </div>
                        </div>

                        <div className="space-y-4">
                            <Input
                                placeholder="Email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <Input
                                placeholder="Password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={onEnter}
                            />

                            <Button onClick={login} className="w-full">
                                Sign In
                            </Button>

                            <Button
                                onClick={resetPassword}
                                variant="outline"
                                className="w-full"
                            >
                                Reset Password
                            </Button>
                        </div>
                    </div>
                )}
            </Card>
        </div>
    );
};

export default SignIn;
