import {
    Column,
    ExpandedState,
    PaginationState,
    Row,
    Table as TableType,
    flexRender,
} from "@tanstack/react-table";
import React, {
    CSSProperties,
    SetStateAction,
    MouseEventHandler,
    useMemo,
    useState,
} from "react";
import {
    DndContext,
    KeyboardSensor,
    UniqueIdentifier,
    MouseSensor,
    TouchSensor,
    closestCenter,
    type DragEndEvent,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/@/components/ui/data-table/table-components";
import { DataTablePagination } from "src/@/components/ui/data-table/table-pagination";
import {
    DataTableToolbar,
    DataTableToolbarOptions,
} from "src/@/components/ui/data-table/table-toolbar";
import { cn } from "src/@/lib/utils";

// needed for table body level scope drag & drop setup

export interface DataTableComponentProps<TData> {
    table: TableType<TData>;
    toolbar?: DataTableToolbarOptions;
    showPagination?: boolean;
    onPaginationChange?: (pagination: PaginationState) => void;
    manualPagination?: boolean;
    footerElement?: JSX.Element | string;
    showHeader?: boolean;
    emptyText?: string;
    className?: string;

    /**
     * Returns a function to be run when the passed in row is clicked.
     */
    getRowClickListener?: (
        row: Row<TData>,
    ) => null | undefined | MouseEventHandler<HTMLTableRowElement>;

    /**
     * Returns a className to be applied to the <TableRow /> of the passed in row.
     */
    getRowClassName?: (row: Row<TData>) => string | undefined | null;

    /**
     * Properties required to allow row drag and drop.
     * To drag and drop rows, you'll need to import and use the row-drag-handle.
     * https://tanstack.com/table/v8/docs/framework/react/examples/row-dnd
     * NOTE: There are some weird bugs with dnd if "columns" is not a consistent reference. Make sure to memoize them or define them outside of the component.
     */
    dragAndDropOptions?: {
        /**
         * Overwrites the data array with the new order after dragging and dropping
         */
        setData: React.Dispatch<SetStateAction<TData[]>>;

        /**
         * Listener on the move action.
         * The data parameter is the data BEFORE the move occurs.
         */
        onMove?: (input: {
            oldIndex: number;
            newIndex: number;
            data: TData[];
        }) => void;

        /**
         * A function that returns a unique identifier on a data row.
         */
        getRowId: (data: TData) => UniqueIdentifier;
    };
}

/**
 * Based on shadcdn Table
 * Create from: https://ui.shadcn.com/examples/tasks
 * https://github.com/shadcn-ui/ui/blob/main/apps/www/app/examples/tasks/components/data-table.tsx
 *
 * Large additional adds aside from the base shadcn table include:
 * - column pinning (https://tanstack.com/table/latest/docs/framework/react/examples/column-pinning-sticky)
 * - row drag & drop (https://tanstack.com/table/v8/docs/framework/react/examples/row-dnd)
 */
export function DataTableComponent<TData>({
    table,
    toolbar,
    showPagination,
    manualPagination = false,
    showHeader = true,
    emptyText = "No Results",
    getRowClickListener = () => null,
    getRowClassName = () => null,
    footerElement,
    className,
    dragAndDropOptions,
}: DataTableComponentProps<TData>) {
    // Styles that are required for pinning. Using style prop because we require programmatic left position.
    // https://tanstack.com/table/latest/docs/framework/react/examples/column-pinning-sticky
    const getCommonPinningStyles = (column: Column<TData>): CSSProperties => {
        const isPinned = column.getIsPinned();
        return {
            left:
                isPinned === "left"
                    ? `${column.getStart("left")}px`
                    : undefined,
            opacity: isPinned ? 0.95 : 1,
            position: isPinned ? "sticky" : "relative",
            width: column.getSize(),
            zIndex: isPinned ? 1 : 0,
        };
    };

    // Save the previous expanded state so we can re-expand after dragging
    const [previousExpandedState, setPreviousExpandedState] =
        useState<ExpandedState>({});

    // sensors required for row drag & drop
    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {}),
    );

    // data ids in order of rows, to be used for re-ordering rows from drag & drop.
    const dataIds = useMemo(() => {
        if (!dragAndDropOptions) return [];
        return table
            .getCoreRowModel()
            .rows.map((e) => dragAndDropOptions.getRowId(e.original));
    }, [dragAndDropOptions, table]);

    // reorder rows after drag & drop
    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        if (dragAndDropOptions && active && over && active.id !== over.id) {
            const { setData, onMove } = dragAndDropOptions;
            setData((data) => {
                const oldIndex = dataIds.indexOf(active.id);
                const newIndex = dataIds.indexOf(over.id);
                onMove?.({ data, oldIndex, newIndex });
                return arrayMove(data, oldIndex, newIndex) as TData[]; //this is just a splice util
            });
        }
        table.setExpanded(previousExpandedState);
    }

    const rows = table.getRowModel().rows;

    return (
        <div className="space-y-4">
            <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={handleDragEnd}
                onDragStart={() => {
                    // we untoggle all rows to avoid DnD's weird interaction with expanded rows
                    setPreviousExpandedState(table.getState().expanded);
                    table.toggleAllRowsExpanded(false);
                }}
                onDragCancel={() => {
                    table.setExpanded(previousExpandedState);
                }}
                sensors={sensors}
            >
                {toolbar && (
                    <DataTableToolbar table={table} toolbar={toolbar} />
                )}
                <div
                    className={cn(
                        "rounded-md border",
                        className,
                        rows.length === 0 && "pointer-events-none",
                        "md:pointer-events-auto",
                        "border-border",
                    )}
                >
                    <Table className="border-border">
                        {showHeader && (
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow
                                        key={headerGroup.id}
                                        className="group/row"
                                    >
                                        {headerGroup.headers.map((header) => (
                                            <TableHead
                                                key={header.id}
                                                style={getCommonPinningStyles(
                                                    header.column,
                                                )}
                                                className={cn(
                                                    header.column.getIsPinned() &&
                                                        "opacity-95 z-10 bg-background",
                                                )}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column
                                                              .columnDef.header,
                                                          header.getContext(),
                                                      )}
                                            </TableHead>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableHeader>
                        )}
                        <TableBody>
                            <SortableContext
                                items={dataIds}
                                strategy={verticalListSortingStrategy}
                            >
                                {rows?.length ? (
                                    rows.map((row) => (
                                        <DraggableRow
                                            key={row.id}
                                            rowId={row.id}
                                            data-state={
                                                row.getIsSelected() &&
                                                "selected"
                                            }
                                            onClick={(event) => {
                                                if (row.getCanExpand())
                                                    row.toggleExpanded();
                                                getRowClickListener(row)?.(
                                                    event,
                                                );
                                            }}
                                            style={{
                                                cursor:
                                                    row.getCanExpand() ||
                                                    getRowClickListener(row)
                                                        ? "pointer"
                                                        : "default",
                                            }}
                                            className={cn(
                                                "group/row",
                                                getRowClassName(row),
                                            )}
                                        >
                                            {row
                                                .getVisibleCells()
                                                .map((cell) => (
                                                    <TableCell
                                                        key={cell.id}
                                                        style={getCommonPinningStyles(
                                                            cell.column,
                                                        )}
                                                        className={cn(
                                                            cell.column.getIsPinned() &&
                                                                "opacity-95 z-10 bg-background",
                                                        )}
                                                    >
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext(),
                                                        )}
                                                    </TableCell>
                                                ))}
                                        </DraggableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={
                                                table.getAllColumns().length
                                            }
                                            className="h-24 pl-8 text-left md:text-center text-muted-foreground"
                                        >
                                            {emptyText}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </SortableContext>
                        </TableBody>
                    </Table>
                </div>
            </DndContext>
            <div className="flex flex-row items-center justify-between">
                <div className="hidden md:block">{footerElement}</div>
                {showPagination && (
                    <DataTablePagination
                        table={table}
                        manualPagination={manualPagination}
                    />
                )}
            </div>
            <div className="block md:hidden">{footerElement}</div>
        </div>
    );
}

interface DraggableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    rowId: string;
}

const DraggableRow: React.FC<DraggableRowProps> = ({
    rowId,
    style,
    ...props
}) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
        id: rowId,
    });

    const dndStyles: CSSProperties = {
        transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: "relative",
        ...style,
    };
    return (
        // connect row ref to dnd-kit, apply important styles
        <TableRow style={dndStyles} ref={setNodeRef} {...props} />
    );
};
