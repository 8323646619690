import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import api from "src/api/rest";
import { getActiveStore } from "src/redux/selectors";
import { logAndSendError } from "src/utils/errors";

import { useDashboardContext } from "./use-dashboard-context";

interface CustomerData {
    name: string;
    value: number;
    color: string;
    orders: number;
}

export interface CustomerResponse {
    userId: string;
    name: string;
    rank: number;
    email: string;
    phone: string;
    lastOrder: string;
    customerSince: string;
    repeatCustomer: number;
    newCustomer: number;
    lifetimeOrders: number;
    lifetimeSpent: number;
    frequency: number;
    appOrders: number;
    onlineOrders: number;
    kioskOrders: number;
    thirdPartyOrders: number;
    totalOrders: number;
    totalSpent: number;
    points: number;
    giftcard: number;
}

type CustomerTotals = {
    new: number;
    repeat: number;
    app: number;
};

export interface AggregateResponse {
    current: CustomerTotals;
    previous: CustomerTotals;
    change: CustomerTotals;
}

interface TransformedCustomer {
    name: string;
    value: number;
    orders: number;
    isRepeatCustomer: boolean;
    isNewCustomer: boolean;
}

interface CustomerChanges {
    appCustomersChange: number;
    newCustomersChange: number;
    repeatCustomersChange: number;
}

const COLORS = [
    "hsl(var(--chart-1))", // blue
    "hsl(var(--chart-2))", // yellow
    "hsl(var(--chart-3))", // purple
    "hsl(var(--chart-4))", // orange
    "hsl(var(--chart-5))", // green
];

export const useQueryCustomerInsights = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [customerData, setCustomerData] = useState<CustomerData[]>([]);
    const [totals, setTotals] = useState<CustomerTotals>({
        app: 0,
        new: 0,
        repeat: 0,
    });
    const [comparisonTotals, setComparisonTotals] = useState<CustomerTotals>({
        app: 0,
        new: 0,
        repeat: 0,
    });

    const store = useSelector(getActiveStore);
    const { currentPeriodStartEnd, comparisonPeriodStartEnd } =
        useDashboardContext();

    const fetchCustomerInsights = useCallback(async () => {
        if (
            !store?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        ) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const response = await api.reports.getCustomerInsights({
                storeId: store._id,
                startDate: currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                compareToStartDate:
                    comparisonPeriodStartEnd?.startDate.format("YYYY-MM-DD"),
                compareToEndDate:
                    comparisonPeriodStartEnd?.endDate.format("YYYY-MM-DD"),
            });

            // Filter and transform the data
            const transformedData: CustomerData[] = response.data.customers
                ?.filter(
                    (customer: CustomerResponse) =>
                        customer.email !== "skippedphone@snackpass.co" &&
                        customer.totalSpent > 0,
                )
                .map(
                    (customer: CustomerResponse): TransformedCustomer => ({
                        name: customer.name || "Anonymous",
                        value: customer.totalSpent,
                        orders: customer.totalOrders,
                        isRepeatCustomer: !!customer.repeatCustomer,
                        isNewCustomer: !!customer.newCustomer,
                    }),
                )
                .sort(
                    (a: TransformedCustomer, b: TransformedCustomer) =>
                        b.value - a.value,
                )
                .slice(0, 12)
                .map((item: TransformedCustomer, index: number) => ({
                    ...item,
                    value: Math.round(item.value), // Round to nearest dollar
                    color: COLORS[index % COLORS.length],
                }));

            setCustomerData(transformedData || []);
            setTotals(response.data.aggregate.current);
            setComparisonTotals(response.data.aggregate.previous);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error(
                    "[useCustomerInsights] Error fetching customer insights:",
                    err,
                );
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [
        store,
        currentPeriodStartEnd?.startDate,
        currentPeriodStartEnd?.endDate,
        comparisonPeriodStartEnd?.startDate,
        comparisonPeriodStartEnd?.endDate,
    ]);

    const calculateChanges = useCallback((): CustomerChanges => {
        const calculatePercentChange = (
            current: number,
            previous: number,
        ): number =>
            previous > 0
                ? Math.round(((current - previous) / previous) * 100)
                : 0;

        const changes = {
            appCustomersChange: calculatePercentChange(
                totals.app,
                comparisonTotals.app,
            ),
            newCustomersChange: calculatePercentChange(
                totals.new,
                comparisonTotals.new,
            ),
            repeatCustomersChange: calculatePercentChange(
                totals.repeat,
                comparisonTotals.repeat,
            ),
        };

        return changes;
    }, [totals, comparisonTotals]);

    useEffect(() => {
        fetchCustomerInsights();
        return;
    }, [
        currentPeriodStartEnd?.startDate,
        currentPeriodStartEnd?.endDate,
        comparisonPeriodStartEnd?.startDate,
        comparisonPeriodStartEnd?.endDate,
        fetchCustomerInsights,
    ]);

    return {
        customerData,
        isLoading,
        error,
        refetch: fetchCustomerInsights,
        dateRange: currentPeriodStartEnd,
        totals,
        comparisonTotals,
        changes: calculateChanges(),
    };
};
