import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Routes as AppRoutes } from "#navigation/routes";
import { ProtectedRoute } from "./components/protected-route";
import { useAppSelector } from "src/redux/hooks";
import { getIsOnboardingComplete } from "src/redux/selectors";
import Dashboard from "#dashboard";
import { directRoutes, lazyRoutes } from "./route-imports";
const Onboarding = lazy(async () => import("#onboarding"));

export function RouterDOM() {
    const isOnboardingComplete = useAppSelector(getIsOnboardingComplete);

    return (
        <Routes>
            <Route
                path={AppRoutes.Index}
                element={
                    !isOnboardingComplete ? (
                        <Navigate to={AppRoutes.Onboarding} replace />
                    ) : (
                        <ProtectedRoute
                            route={AppRoutes.Index}
                            element={<Dashboard />}
                        />
                    )
                }
            />
            <Route
                path={AppRoutes.Onboarding}
                element={
                    <ProtectedRoute
                        route={AppRoutes.Onboarding}
                        element={<Onboarding />}
                    />
                }
            />

            {directRoutes.map(({ path, Component }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <ProtectedRoute route={path} element={<Component />} />
                    }
                />
            ))}
            {lazyRoutes.map(({ path, import: importFn }) => {
                const LazyComponent = lazy(importFn as any);
                return (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <ProtectedRoute
                                route={path as AppRoutes}
                                element={<LazyComponent />}
                            />
                        }
                    />
                );
            })}

            <Route
                path="*"
                element={<Navigate to={AppRoutes.NotFound} replace />}
            />
        </Routes>
    );
}
