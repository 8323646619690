import { useCallback, useState } from "react";
import { min } from "date-fns";
import moment from "moment";

import { FilterSelect } from "src/@/components/ui/filter-select";
import { DateRangePicker } from "src/@/components/legacy/DateRangePicker";
import { Button } from "src/@/components/ui/button";
import { CalendarIcon } from "lucide-react";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";
import { CurrentPeriod } from "#dashboard/types";
import { getCurrentPeriodRange } from "#dashboard/utils/date-range-utils";

export const CurrentPeriodPicker = () => {
    const { currentPeriod, currentPeriodStartEnd, setCurrentPeriod } =
        useDashboardContext();

    const [customDates, setCustomDates] = useState<{ from: Date; to: Date }>({
        from: currentPeriodStartEnd?.startDate?.toDate() ?? new Date(),
        to: min([
            currentPeriodStartEnd?.endDate?.toDate() ?? new Date(),
            new Date(),
        ]),
    });

    const [isOpen, setIsOpen] = useState(false);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const formatDate = (date: Date) => {
        return moment(date).format("M/D/YY");
    };

    const getButtonText = () => {
        return (
            <div className="flex items-center gap-2">
                <span>{currentPeriod}</span>
            </div>
        );
    };

    const dateRange =
        currentPeriodStartEnd?.startDate && currentPeriodStartEnd?.endDate
            ? `${formatDate(
                  currentPeriodStartEnd.startDate.toDate(),
              )} - ${formatDate(currentPeriodStartEnd.endDate.toDate())}`
            : "";

    const handleCustomDateChange = useCallback(
        (value: { from?: Date; to?: Date }) => {
            if (!value?.from || !value?.to) {
                return;
            }

            setCurrentPeriod(CurrentPeriod.CUSTOM, {
                startDate: moment(value.from).startOf("day"),
                endDate: moment(value.to).endOf("day"),
            });

            setCustomDates({
                from: value.from,
                to: value.to,
            });
        },
        [setCurrentPeriod],
    );

    const handlePeriodSelection = useCallback(
        (value: string) => {
            const newDuration = value as CurrentPeriod;

            if (newDuration === CurrentPeriod.CUSTOM) {
                setIsDatePickerOpen(true);
                setIsOpen(false);
                setCurrentPeriod(CurrentPeriod.CUSTOM);
                return;
            }

            setCurrentPeriod(newDuration);

            // Update local state
            const newRange = getCurrentPeriodRange(newDuration);
            setCustomDates({
                from: newRange.startDate.toDate(),
                to: newRange.endDate.toDate(),
            });
        },
        [setCurrentPeriod],
    );

    const options = Object.values(CurrentPeriod)
        .map((e) => ({
            label: e,
            value: e,
        }))
        .filter((option) => option.value !== CurrentPeriod.CUSTOM);

    options.push({
        label: CurrentPeriod.CUSTOM,
        value: CurrentPeriod.CUSTOM,
    });

    return (
        <div className="flex flex-col gap-2">
            <div className="flex items-center">
                <DateRangePicker
                    disableFutureDates
                    onSelect={(value) => {
                        if (!value) {
                            handleCustomDateChange({
                                from: customDates.from,
                                to: customDates.from,
                            });
                        } else if (!value?.to) {
                            handleCustomDateChange({
                                from: value.from,
                                to: value.from,
                            });
                        } else {
                            const { from, to } = value;
                            handleCustomDateChange({ from, to });
                        }
                    }}
                    selected={customDates}
                    open={isDatePickerOpen}
                    onOpenChange={setIsDatePickerOpen}
                    className="invisible w-0"
                />
                <FilterSelect
                    customButton={
                        <Button
                            variant="outline"
                            size="sm"
                            className="flex items-center"
                        >
                            <CalendarIcon className="h-4 w-4" />
                            <span className="">{getButtonText()}</span>
                        </Button>
                    }
                    title={currentPeriod}
                    subtitle={dateRange}
                    selectedValues={new Set([currentPeriod])}
                    options={options}
                    onOptionSelected={handlePeriodSelection}
                    open={isOpen}
                    onOpenChange={setIsOpen}
                />
            </div>
        </div>
    );
};
