import * as React from "react";

import { Section } from "./components/section";
import { NavUser } from "./components/nav-user";
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from "src/@/components/ui/sidebar";
import { useCreateSidebar } from "./hooks/use-create-sidebar";
import { StoreCombobox } from "#store-selector/store-combobox";
import { SearchCommandBarProvider } from "#search-command-bar/search-command-bar-provider";
import { SearchCommandBar } from "#search-command-bar";
import { Separator } from "@radix-ui/react-separator";
import { useLoadActiveStoreNumUnreads } from "#navigation/sidebar/hooks/use-load-num-reads";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const { sections } = useCreateSidebar();

    // todo - would like to move this to initial hooks
    // but there is conflict with pubnub
    useLoadActiveStoreNumUnreads();

    return (
        <SearchCommandBarProvider>
            <SearchCommandBar />
            <Sidebar variant="inset" {...props}>
                <SidebarHeader>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <SidebarMenuButton size="lg" asChild>
                                <div className="cursor-pointer flex items-center w-full data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground select-none">
                                    <StoreCombobox />
                                </div>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarHeader>
                <SidebarContent className="mb-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] gap-1">
                    {sections.map((section) => (
                        <Section key={section.key} section={section} />
                    ))}
                </SidebarContent>
                <SidebarFooter>
                    <NavUser />
                    <div className="px-2 space-y-2 mb-1">
                        <Separator
                            orientation="horizontal"
                            className="w-full h-[1px] bg-border mb-3"
                        />
                        <img
                            className="opacity-50 dark:invert"
                            src="/snackpass-logo.svg"
                            alt="Snackpass"
                            width={100}
                            height={38}
                        />
                    </div>
                </SidebarFooter>
            </Sidebar>
        </SearchCommandBarProvider>
    );
}
