import { useGetStoreStatsQuery } from "src/api/graphql/generated/types";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";

export function useQuerySMSSubscribers() {
    const activeStore = useSelector(getActiveStore);

    const { data, loading, error } = useGetStoreStatsQuery({
        variables: {
            storeId: activeStore?._id || "",
        },
        skip: !activeStore,
    });

    const subscriberCount =
        data?.storeById?.stats?.totalReachableSMSCustomers ?? 0;

    return {
        subscriberCount,
        loading,
        error,
    };
}
