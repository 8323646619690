import { useLocation } from "react-router-dom";
import { cn } from "src/@/lib/utils";
import { Routes } from "#navigation/routes";
import { useMemo } from "react";

export function useCustomLayoutStyles() {
    const location = useLocation();

    const layoutClassName = useMemo(() => {
        const isGuestbookConversations = location.pathname.includes(
            Routes.GuestbookConversations,
        );

        return cn(
            "p-4 sm:p-12 pt-0 sm:pt-4 pb-4 sm:pb-4",
            isGuestbookConversations && "h-full",
        );
    }, [location.pathname]);

    return { layoutClassName };
}
