window.global ||= window;
import { createRoot } from "react-dom/client";

// CSS
// import "antd/dist/antd.css";
import "react-table/react-table.css";

import "#css/globals.css";

// Sentry
import * as Sentry from "@sentry/react";
import { extraErrorDataIntegration } from "@sentry/integrations";

import App from "#app/app";
import config from "#config";
import "#app/genericErrorHandler";
import { beforeSend } from "src/utils/beforeSend";

import { unregister } from "src/serviceWorker";
import { loadIntercom } from "src/utils/intercomSnippet";
import { signinPreload } from "src/utils/signinPreload";

if (config.env === "production") {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        release: config.releaseVersion,
        normalizeDepth: 10,
        integrations: [extraErrorDataIntegration({ depth: 10 })],
        beforeSend,
    });
}

// Unregister servicerWorker to bust browser cache on new builds
unregister();
loadIntercom();

async function bootstrap() {
    const container = document.getElementById("root");
    if (container) {
        const root = createRoot(container);
        signinPreload();
        root.render(<App />);
    }
}

bootstrap();
