import { Link } from "react-router-dom";
import { FeatureCardContent } from "./feature-card-content";
import {
    useFeaturedShortcuts,
    FeaturedShortcutData,
} from "../hooks/use-featured-shortcuts";
import { useTrackSegmentEvent } from "#utils/segment";
import { SegmentEvents } from "#utils/segment";

const baseCardClassName =
    "relative h-96 overflow-hidden rounded-lg group hover:shadow-lg transition-all duration-500 ease-in-out";

function InteractiveCard({ shortcut }: { shortcut: FeaturedShortcutData }) {
    const trackEvent = useTrackSegmentEvent();

    const handleClick = () => {
        trackEvent(SegmentEvents.CLICKED_FEATURED_SHORTCUT, {
            shortcutId: shortcut.id,
            shortcutTitle: shortcut.title,
        });
        shortcut.onClick?.();
    };

    return (
        <div
            key={shortcut.id}
            onClick={handleClick}
            className={`${baseCardClassName} cursor-pointer`}
        >
            <FeatureCardContent
                image={shortcut.image}
                title={shortcut.title}
                description={shortcut.description}
            />
        </div>
    );
}

function ExternalCard({ shortcut }: { shortcut: FeaturedShortcutData }) {
    const trackEvent = useTrackSegmentEvent();

    const handleClick = () => {
        trackEvent(SegmentEvents.CLICKED_FEATURED_SHORTCUT, {
            shortcutId: shortcut.id,
            shortcutTitle: shortcut.title,
            link: shortcut.link,
        });
    };

    return (
        <a
            href={shortcut.link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
            className={baseCardClassName}
        >
            <FeatureCardContent
                image={shortcut.image}
                title={shortcut.title}
                description={shortcut.description}
            />
        </a>
    );
}

function InternalCard({ shortcut }: { shortcut: FeaturedShortcutData }) {
    const trackEvent = useTrackSegmentEvent();

    const handleClick = () => {
        trackEvent(SegmentEvents.CLICKED_FEATURED_SHORTCUT, {
            shortcutId: shortcut.id,
            shortcutTitle: shortcut.title,
            link: shortcut.link,
        });
    };

    return (
        <Link
            to={shortcut.link}
            className={baseCardClassName}
            onClick={handleClick}
        >
            <FeatureCardContent
                image={shortcut.image}
                title={shortcut.title}
                description={shortcut.description}
            />
        </Link>
    );
}

export function FeatureGrid() {
    const featuredShortcuts = useFeaturedShortcuts();

    return (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
            {featuredShortcuts.map((shortcut) => {
                if (shortcut.onClick) {
                    return (
                        <InteractiveCard
                            key={shortcut.id}
                            shortcut={shortcut}
                        />
                    );
                }

                if (shortcut.link.startsWith("http")) {
                    return (
                        <ExternalCard key={shortcut.id} shortcut={shortcut} />
                    );
                }

                return <InternalCard key={shortcut.id} shortcut={shortcut} />;
            })}
        </div>
    );
}
