import { PieChart, Pie, Legend, Cell } from "recharts";
import { ChartContainer, ChartTooltip } from "src/@/components/ui/chart";
import { ChartCard } from "./chart-card";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";
import { SalesBreakdown } from "#dashboard/hooks/use-query-sales-cohorts";

const sourceChartConfig = {
    total: {
        label: "Sales by Channel",
        color: "var(--color-current)",
    },
} as const;

const fulfillmentChartConfig = {
    total: {
        label: "Sales by Fulfillment",
        color: "var(--color-current)",
    },
} as const;

const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(value);
};

const calculatePercentage = (value: number, total: number): number => {
    if (!total) return 0;
    return (value / total) * 100;
};

interface SalesBreakdownChartsProps {
    breakdown: SalesBreakdown;
    isLoading: boolean;
}

export const SalesBreakdownCharts = ({
    breakdown,
    isLoading,
}: SalesBreakdownChartsProps) => {
    const { currentPeriodStartEnd } = useDashboardContext();
    const startDate = currentPeriodStartEnd?.startDate?.format("MM/DD/YY");
    const endDate = currentPeriodStartEnd?.endDate?.format("MM/DD/YY");

    // Calculate totals for percentages
    const totalChannelSales = breakdown.channelData.reduce(
        (sum, item) => sum + item.value,
        0,
    );
    const totalFulfillmentSales = breakdown.fulfillmentData.reduce(
        (sum, item) => sum + item.value,
        0,
    );

    const renderCustomLabel = (entry: any) => {
        const percent = calculatePercentage(entry.value, totalChannelSales);
        return `${percent.toFixed(1)}%`;
    };

    return (
        <>
            <ChartCard
                title="Sales by Channel"
                isLoading={isLoading}
                description={
                    startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : undefined
                }
            >
                <ChartContainer
                    config={sourceChartConfig}
                    className="[&_.recharts-pie-label-text]:font-medium"
                >
                    <PieChart>
                        <Pie
                            data={breakdown.channelData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            innerRadius="50%"
                            dataKey="value"
                            nameKey="name"
                            label={renderCustomLabel}
                        >
                            {breakdown.channelData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}
                        </Pie>
                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="middle"
                            iconType="circle"
                        />
                        <ChartTooltip
                            content={({ active, payload }) => {
                                if (!active || !payload?.length) return null;
                                const data = payload[0].payload;
                                return (
                                    <div className="rounded-lg bg-popover p-2 shadow-md border">
                                        <div className="font-medium">
                                            {data.name}
                                        </div>
                                        <div className="text-muted-foreground">
                                            {formatCurrency(data.value)} in
                                            sales
                                        </div>
                                        {data.change && (
                                            <div className="text-muted-foreground">
                                                {data.change > 0 ? "+" : ""}
                                                {data.change.toFixed(2)}% vs
                                                previous
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </PieChart>
                </ChartContainer>
            </ChartCard>
            <ChartCard
                title="Sales by Fulfillment"
                isLoading={isLoading}
                description={
                    startDate && endDate
                        ? `${startDate} - ${endDate}`
                        : undefined
                }
            >
                <ChartContainer
                    config={fulfillmentChartConfig}
                    className="[&_.recharts-pie-label-text]:font-medium"
                >
                    <PieChart>
                        <Pie
                            data={breakdown.fulfillmentData}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            innerRadius="50%"
                            dataKey="value"
                            nameKey="name"
                            label={(entry) => {
                                const percent = calculatePercentage(
                                    entry.value,
                                    totalFulfillmentSales,
                                );
                                return `${percent.toFixed(1)}%`;
                            }}
                        >
                            {breakdown.fulfillmentData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={entry.color}
                                />
                            ))}
                        </Pie>
                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="middle"
                            iconType="circle"
                        />
                        <ChartTooltip
                            content={({ active, payload }) => {
                                if (!active || !payload?.length) return null;
                                const data = payload[0].payload;
                                return (
                                    <div className="rounded-lg bg-popover p-2 shadow-md border">
                                        <div className="font-medium">
                                            {data.name}
                                        </div>
                                        <div className="text-muted-foreground">
                                            {formatCurrency(data.value)} in
                                            sales
                                        </div>
                                        {data.change && (
                                            <div className="text-muted-foreground">
                                                {data.change > 0 ? "+" : ""}
                                                {data.change.toFixed(2)}% vs
                                                previous
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </PieChart>
                </ChartContainer>
            </ChartCard>
        </>
    );
};
