import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import api from "src/api/rest";
import { getStoreTimezone } from "#utils/helpers";
import { useSelector } from "react-redux";
import { getActiveStore } from "src/redux/selectors";
import { useDashboardContext } from "./use-dashboard-context";
import { logAndSendError } from "src/utils/errors";

interface SalesCohortItem {
    averageOrderSize: number;
    averageOrderSizeChange: number;
    averageOrderSizeComparedTo: number;
    channel: string;
    fulfillment: string;
    items: number;
    itemsChange: number;
    itemsComparedTo: number;
    netSales: number;
    netSalesChange: number;
    netSalesComparedTo: number;
    orders: number;
    ordersChange: number;
    ordersComparedTo: number;
    rating: string | null;
}

interface SalesSummaryByCohort {
    salesSummaryByCohort: SalesCohortItem[];
}

interface BreakdownItem {
    name: string;
    value: number;
    color: string;
    change?: number;
    comparedTo?: number;
}

interface FeedbackData {
    name: string;
    value: number;
    comparedTo: number;
    color: string;
}

export interface SalesBreakdown {
    channelData: BreakdownItem[];
    sourceData: BreakdownItem[];
    fulfillmentData: BreakdownItem[];
    feedbackData: FeedbackData[];
}
// Define consistent color mappings
const CHANNEL_COLORS: { [key: string]: string } = {
    app: "hsl(var(--chart-1))", // Mobile - Blue
    kiosk: "hsl(var(--chart-2))", // Kiosk - Yellow
    online: "hsl(var(--chart-3))", // Online - Purple
    register: "hsl(var(--chart-4))", // Register - Green
    thirdparty: "hsl(var(--chart-5))", // Third Party - Teal
};

const FULFILLMENT_COLORS: { [key: string]: string } = {
    PICKUP: "hsl(var(--chart-1))", // Blue
    DELIVERY: "hsl(var(--chart-2))", // Orange
    DINE_IN: "hsl(var(--chart-3))", // Green
};

const RATING_COLORS: { [key: string]: string } = {
    GOOD: "hsl(var(--chart-4))", // Green
    BAD: "hsl(var(--chart-5))", // Red
    null: "hsl(var(--chart-6))", // Gray for N/A
};

// Default color for any unmapped values
const DEFAULT_COLOR = "#999DA3"; // Gray
const calculateChangePercentage = (
    current: number,
    previous: number,
): number => {
    if (!previous) return 0;
    return ((current - previous) / previous) * 100;
};
export const useQuerySalesCohorts = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [breakdown, setBreakdown] = useState<SalesBreakdown>({
        channelData: [],
        sourceData: [],
        fulfillmentData: [],
        feedbackData: [],
    });

    const store = useSelector(getActiveStore);
    const {
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
        providers,
        channels,
        paymentMethods,
    } = useDashboardContext();

    const transformCohortData = (
        cohorts: SalesCohortItem[],
    ): SalesBreakdown => {
        const channelMap = new Map<string, SalesCohortItem>();
        const fulfillmentMap = new Map<string, SalesCohortItem>();
        const feedbackMap = new Map<
            string,
            { name: string; value: number; comparedTo: number }
        >();
        // Group by channel and fulfillment
        cohorts.forEach((cohort) => {
            if (cohort.channel) {
                // Aggregate data if same channel exists
                const existing = channelMap.get(cohort.channel);
                if (existing) {
                    existing.netSales += cohort.netSales || 0;
                    existing.netSalesComparedTo +=
                        cohort.netSalesComparedTo || 0;
                    // Keep track of the best rating
                    existing.rating = getBestRating(
                        existing.rating,
                        cohort.rating,
                    );
                } else {
                    channelMap.set(cohort.channel, {
                        ...cohort,
                        netSales: cohort.netSales || 0,
                        netSalesComparedTo: cohort.netSalesComparedTo || 0,
                    });
                }
            }
            if (cohort.fulfillment) {
                // Aggregate data if same fulfillment exists
                const existing = fulfillmentMap.get(cohort.fulfillment);
                if (existing) {
                    existing.netSales += cohort.netSales || 0;
                    existing.netSalesComparedTo +=
                        cohort.netSalesComparedTo || 0;
                    // Keep track of the best rating
                    existing.rating = getBestRating(
                        existing.rating,
                        cohort.rating,
                    );
                } else {
                    fulfillmentMap.set(cohort.fulfillment, {
                        ...cohort,
                        netSales: cohort.netSales || 0,
                        netSalesComparedTo: cohort.netSalesComparedTo || 0,
                    });
                }
            }
            if (cohort.rating) {
                const existing = feedbackMap.get(cohort.rating);
                if (existing) {
                    existing.value += cohort.orders || 0;
                    existing.comparedTo += cohort.ordersComparedTo || 0;
                } else {
                    feedbackMap.set(cohort.rating, {
                        name: cohort.rating,
                        value: cohort.orders || 0,
                        comparedTo: cohort.ordersComparedTo || 0,
                    });
                }
            }
        });

        // Transform channel data
        const channelData = Array.from(channelMap.entries())
            .map(([name, data]) => ({
                name: formatChannelName(name),
                value: data.netSales,
                color: CHANNEL_COLORS[name.toLowerCase()] || DEFAULT_COLOR,
                change: calculateChangePercentage(
                    data.netSales,
                    data.netSalesComparedTo,
                ),
                comparedTo: data.netSalesComparedTo,
                rating: formatRating(data.rating),
                ratingColor:
                    RATING_COLORS[data.rating || "null"] || DEFAULT_COLOR,
            }))
            .sort((a, b) => b.value - a.value);

        // Transform fulfillment data
        const fulfillmentData = Array.from(fulfillmentMap.entries())
            .map(([name, data]) => ({
                name: formatFulfillmentName(name),
                value: data.netSales,
                color: FULFILLMENT_COLORS[name.toUpperCase()] || DEFAULT_COLOR,
                change: calculateChangePercentage(
                    data.netSales,
                    data.netSalesComparedTo,
                ),
                comparedTo: data.netSalesComparedTo,
                rating: formatRating(data.rating),
                ratingColor:
                    RATING_COLORS[data.rating || "null"] || DEFAULT_COLOR,
            }))
            .sort((a, b) => b.value - a.value);

        const feedbackData = Array.from(feedbackMap.entries()).map(
            ([name, data]) => ({
                name: formatRating(name),
                value: data.value,
                comparedTo: data.comparedTo,
                color: RATING_COLORS[name] || DEFAULT_COLOR,
                change: calculateChangePercentage(data.value, data.comparedTo),
            }),
        );

        return {
            channelData,
            fulfillmentData,
            feedbackData,
            sourceData: [],
        };
    };

    // Helper functions to format display names
    const formatChannelName = (channel: string): string => {
        const channelMap: { [key: string]: string } = {
            app: "Mobile",
            kiosk: "Kiosk",
            online: "Online",
            register: "Register",
            thirdparty: "Third Party",
        };
        return channelMap[channel.toLowerCase()] || channel;
    };

    const formatFulfillmentName = (fulfillment: string): string => {
        const fulfillmentMap: { [key: string]: string } = {
            PICKUP: "Pickup",
            DELIVERY: "Delivery",
            DINE_IN: "Dine In",
        };
        return fulfillmentMap[fulfillment.toUpperCase()] || fulfillment;
    };

    const formatRating = (rating: string | null): string => {
        const ratingMap: { [key: string]: string } = {
            GOOD: "Good",
            BAD: "Bad",
            null: "N/A",
        };
        return ratingMap[rating || "null"] || "N/A";
    };

    // Helper function to determine the best rating between two ratings
    const getBestRating = (
        rating1: string | null,
        rating2: string | null,
    ): string | null => {
        if (rating1 === "GOOD" || rating2 === "GOOD") return "GOOD";
        if (rating1 === "BAD" || rating2 === "BAD") return "BAD";
        return null;
    };

    const fetchSalesBreakdown = useCallback(async () => {
        if (
            !store?._id ||
            !currentPeriodStartEnd?.startDate ||
            !currentPeriodStartEnd?.endDate
        ) {
            return;
        }

        try {
            setIsLoading(true);
            setError(null);

            const params = {
                storeId: store._id,
                startDate: currentPeriodStartEnd.startDate.format("YYYY-MM-DD"),
                endDate: currentPeriodStartEnd.endDate.format("YYYY-MM-DD"),
                comparedToStartDate:
                    comparisonPeriodStartEnd?.startDate?.format("YYYY-MM-DD") ||
                    "",
                comparedToEndDate:
                    comparisonPeriodStartEnd?.endDate?.format("YYYY-MM-DD") ||
                    "",
                timezone: getStoreTimezone(store),
                channel: JSON.stringify(providers),
                source: JSON.stringify(channels),
                fulfillment: JSON.stringify([]),
            };

            const response = await api.reports.getSalesCohortReport(params);

            const cohortData = response.data as SalesSummaryByCohort;
            const transformedBreakdown = transformCohortData(
                cohortData.salesSummaryByCohort,
            );

            setBreakdown(transformedBreakdown);
        } catch (err) {
            if (!axios.isCancel(err)) {
                console.error("Error fetching sales breakdown:", err);
                logAndSendError(err);
                setError(err as Error);
            }
        } finally {
            setIsLoading(false);
        }
    }, [
        store,
        currentPeriodStartEnd,
        comparisonPeriodStartEnd,
        channels,
        providers,
        paymentMethods,
    ]);

    useEffect(() => {
        fetchSalesBreakdown();

        return;
    }, [fetchSalesBreakdown]);

    return {
        breakdown,
        isLoading,
        error,
        refetch: fetchSalesBreakdown,
    };
};
