import { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowRight, Search } from "lucide-react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card";
import { Input } from "src/@/components/ui/input";
import { quickShortcuts, iconComponents } from "./quick-shortcuts-data";
import { useTrackSegmentEvent } from "#utils/segment";
import { SegmentEvents } from "#utils/segment";

export function QuickShortcutsCard() {
    const [searchQuery, setSearchQuery] = useState("");
    const trackEvent = useTrackSegmentEvent();

    const filteredShortcuts = quickShortcuts.filter((shortcut) =>
        shortcut.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    const handleShortcutClick = (shortcut: (typeof quickShortcuts)[0]) => {
        trackEvent(SegmentEvents.CLICKED_QUICK_SHORTCUT, {
            shortcutId: shortcut.id,
            shortcutTitle: shortcut.title,
            shortcutLink: shortcut.link,
        });
    };

    // THIS STYLING IS TRICKY DO NOT TOUCH
    // needed to make shortcuts scroll absolute position to let the parent flex-1 grow
    return (
        <Card className="h-full flex flex-col">
            <CardHeader className="pb-1">
                <CardTitle className="text-sm font-medium text-muted-foreground">
                    Quick Actions
                </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col h-full flex-1">
                <div className="relative pb-1">
                    <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
                    <Input
                        type="search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="pl-10 pr-4 bg-muted/50"
                    />
                </div>
                <div className="flex-1 relative w-full">
                    <div className="overflow-y-scroll absolute h-full py-4 w-full flex flex-col gap-2 scrollbar-hide scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-blue-500">
                        {filteredShortcuts.map((shortcut) => {
                            const isExternalLink =
                                shortcut.link.startsWith("http");
                            const LinkComponent = isExternalLink ? "a" : Link;
                            const linkProps = isExternalLink
                                ? {
                                      href: shortcut.link,
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                  }
                                : { to: shortcut.link };

                            const IconComponent = iconComponents[shortcut.icon];

                            return (
                                <LinkComponent
                                    key={shortcut.id}
                                    {...(linkProps as any)}
                                    className="flex w-full items-center justify-between rounded-lg border bg-card p-3 text-sm text-card-foreground shadow-sm hover:bg-accent hover:text-accent-foreground no-underline transition-colors"
                                    onClick={() =>
                                        handleShortcutClick(shortcut)
                                    }
                                >
                                    <div className="flex items-center gap-2">
                                        <IconComponent className="h-4 w-4" />
                                        <span>{shortcut.title}</span>
                                    </div>
                                    <ArrowRight className="h-4 w-4" />
                                </LinkComponent>
                            );
                        })}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
