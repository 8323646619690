import { Bar, BarChart, CartesianGrid, Cell, XAxis, YAxis } from "recharts";
import { ChartContainer, ChartTooltip } from "src/@/components/ui/chart";
import { ChartCard } from "./chart-card";
import { useQueryPromotionsReports } from "#dashboard/hooks/use-query-promotions-reports";
import { useDashboardContext } from "#dashboard/hooks/use-dashboard-context";

const COLORS = [
    "hsl(var(--chart-1))", // blue
    "hsl(var(--chart-2))", // yellow
    "hsl(var(--chart-3))", // purple
    "hsl(var(--chart-4))", // orange
    "hsl(var(--chart-5))", // green
];

const promotionsChartConfig = {
    total: {
        label: "Top Promotions",
        color: "hsl(var(--primary))",
    },
} as const;

export const TopPromotionsChart = () => {
    const { promotions, isLoading } = useQueryPromotionsReports();
    const { currentPeriodStartEnd } = useDashboardContext();

    const startDate = currentPeriodStartEnd?.startDate?.format("MM/DD/YY");
    const endDate = currentPeriodStartEnd?.endDate?.format("MM/DD/YY");

    return (
        <ChartCard
            title="Top Promotions"
            description={
                startDate && endDate ? `${startDate} - ${endDate}` : undefined
            }
            to="/promo-reports"
            isLoading={isLoading}
        >
            <ChartContainer config={promotionsChartConfig}>
                <BarChart
                    data={promotions}
                    layout="vertical"
                    margin={{
                        top: 0,
                        right: 12,
                        bottom: 0,
                        left: 70,
                    }}
                >
                    <CartesianGrid horizontal={false} />
                    <XAxis
                        type="number"
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                    />
                    <YAxis
                        type="category"
                        dataKey="name"
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                    />
                    <ChartTooltip
                        cursor={false}
                        content={({ active, payload }) => {
                            if (!active || !payload?.length) return null;
                            const data = payload[0].payload;
                            return (
                                <div className="space-y-2 rounded-lg border bg-popover p-2 shadow-md">
                                    <div className="font-medium">
                                        {data.name}
                                    </div>
                                    <div className="text-muted-foreground">
                                        {data.redemptions} redemptions
                                    </div>
                                    <div className="text-muted-foreground">
                                        ${data.revenue.toFixed(2)} revenue
                                    </div>
                                </div>
                            );
                        }}
                    />
                    <Bar dataKey="redemptions" radius={[0, 4, 4, 0]}>
                        {promotions.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ChartContainer>
        </ChartCard>
    );
};
