import { RouteItems } from "#navigation/route-data";
import { Routes } from "#navigation/routes";
import { SidebarSection } from "../types";

const quickActions = [
    RouteItems[Routes.Search],
    RouteItems[Routes.Onboarding],
    RouteItems[Routes.WhatsNew],
    RouteItems[Routes.Support],
];

const platform = [
    RouteItems[Routes.Index],
    RouteItems[Routes.BetaOrders],
    {
        ...RouteItems[Routes.Menu],
        items: [
            RouteItems[Routes.MenuEditor],
            RouteItems[Routes.MenuManager],
            RouteItems[Routes.MultiMenus],
            RouteItems[Routes.MenuInventoryManager],
        ],
    },
];

const growth = [
    {
        ...RouteItems[Routes.Guestbook],
        items: [
            RouteItems[Routes.GuestbookConversations],
            RouteItems[Routes.GuestbookCustomers],
            RouteItems[Routes.GuestbookAuditLog],
        ],
    },
    {
        ...RouteItems[Routes.Marketing],
        items: [
            RouteItems[Routes.Promotion],
            RouteItems[Routes.SettingsGiftCard],
            RouteItems[Routes.GuestbookCampaignsSMS],
            RouteItems[Routes.GuestbookCampaignsPush],
        ],
    },
    {
        ...RouteItems[Routes.DigitalPresence],
        items: [
            RouteItems[Routes.DigitalPresenceOnlineOrdering],
            RouteItems[Routes.SettingsQrCode],
            RouteItems[Routes.DigitalPresenceApp],
            RouteItems[Routes.DigitalPresenceWebsite],
            RouteItems[Routes.SettingsCatering],
        ],
    },
    {
        ...RouteItems[Routes.BusinessTools],
        items: [
            RouteItems[Routes.BuyHardware],
            RouteItems[Routes.Invoices],
            RouteItems[Routes.SettingsIntegrations],
        ],
    },
];
const data = [
    {
        ...RouteItems[Routes.SalesReports],
        items: [
            RouteItems[Routes.FinancialReportsEssentials],
            RouteItems[Routes.MinuteToMinute],
            RouteItems[Routes.ReportsSalesSummary],
            RouteItems[Routes.FinancialReportsPayoutHistory],
        ],
    },
    {
        ...RouteItems[Routes.Labor],
        items: [
            RouteItems[Routes.EmployeeTimeCards],
            RouteItems[Routes.Tipping],
        ],
    },
    {
        ...RouteItems[Routes.Reports],
        items: [
            RouteItems[Routes.ReportsCustomerDirectoryInsights],
            {
                ...RouteItems[Routes.ReportsMenu],
                items: [
                    RouteItems[Routes.ReportsMenuItemInsights],
                    RouteItems[Routes.ReportsMenuCategoryInsights],
                ],
            },
            RouteItems[Routes.ReportsSalesChannels],
            RouteItems[Routes.ReportsPromotions],
            {
                ...RouteItems[Routes.ReportsGiftCard],
                items: [
                    RouteItems[Routes.ReportsGiftCardPurchases],
                    RouteItems[Routes.ReportsGiftCardBalances],
                    RouteItems[Routes.ReportsGiftCardTransactions],
                    RouteItems[Routes.ReportsGiftCardLiabilities],
                ],
            },
            RouteItems[Routes.FinancialReportsCash],
            {
                ...RouteItems[Routes.ReportsLocation],
                items: [
                    RouteItems[Routes.ReportsLocationSales],
                    RouteItems[Routes.ReportsLocationMenuCategories],
                    RouteItems[Routes.ReportsLocationMenuItems],
                ],
            },
        ],
    },
];

const settings = [
    RouteItems[Routes.SettingsBusinessInfo],
    {
        ...RouteItems[Routes.SettingsTeam],
        items: [
            RouteItems[Routes.SettingsPermissions],
            RouteItems[Routes.SettingsEmployees],
        ],
    },
    RouteItems[Routes.Devices],
    {
        ...RouteItems[Routes.SettingsFrontOfHouse],
        items: [
            RouteItems[Routes.SettingsBrandAndAppearance],
            RouteItems[Routes.SettingsTipping],
            RouteItems[Routes.SettingsAllOrders],
            RouteItems[Routes.SettingsOnlineOrders],
            RouteItems[Routes.SettingsPlatforms],
        ],
    },
    {
        ...RouteItems[Routes.SettingsBOH],
        items: [
            RouteItems[Routes.SettingsBackOfHouse],
            RouteItems[Routes.PrepStations],
            RouteItems[Routes.SettingsStoreTablesLayout],
        ],
    },
    {
        ...RouteItems[Routes.AccountAndTax],
        items: [
            RouteItems[Routes.Payouts],
            RouteItems[Routes.PayoutsSchedule],
            RouteItems[Routes.SettingsTax],
            RouteItems[Routes.TaxForms],
            RouteItems[Routes.Billing],
        ],
    },
    {
        ...RouteItems[Routes.Other],
        items: [
            RouteItems[Routes.SettingsProducts],
            RouteItems[Routes.SettingsLegal],
            {
                ...RouteItems[Routes.SnackpassAdmin],
                items: [
                    RouteItems[Routes.InternalSettings],
                    RouteItems[Routes.InternalFees],
                    RouteItems[Routes.InternalSeniorSupport],
                    RouteItems[Routes.InternalDevices],
                ],
            },
        ],
    },
];

export const sidebarSections: readonly SidebarSection[] = [
    {
        key: "quick-actions",
        items: quickActions,
    },
    {
        key: "store",
        label: "Store",
        defaultOpen: true,
        items: platform,
    },
    {
        key: "growth",
        label: "Growth",
        defaultOpen: true,
        items: growth,
    },
    {
        key: "data",
        label: "Data",
        defaultOpen: true,
        items: data,
    },
    {
        key: "settings",
        label: "Settings",
        defaultOpen: false,
        items: settings,
    },
] as const;
