import { useCallback } from "react";
import { useAppSelector } from "src/redux/hooks";
import {
    getActiveStore,
    getIsOnboardingComplete,
    getUser,
    getUserIsSnackpassEmployee,
} from "src/redux/selectors";
import { Routes } from "#navigation/routes";
import { StorePermissions } from "#navigation/types";
import { INTERNAL_ROUTES, RouteItems } from "#navigation/route-data";

export function useRoutePermissions() {
    const activeStore = useAppSelector(getActiveStore);
    const user = useAppSelector(getUser);
    const isSPEmployee = useAppSelector(getUserIsSnackpassEmployee);
    const isOnboardingComplete = useAppSelector(getIsOnboardingComplete);

    const storePermissions = (user?.permissions ?? {}) as StorePermissions;
    const activeStoreId = activeStore?._id || "";
    const isOwner = storePermissions?.isOwner?.includes(activeStoreId);
    // be careful about the order!
    const hasRoutePermission = useCallback(
        (route: Routes): boolean => {
            // Internal routes require SP Employee
            if (INTERNAL_ROUTES.includes(route)) {
                return isSPEmployee;
            }

            // SP Employee has access to everything after onboarding
            if (isSPEmployee) {
                return true;
            }

            // Owners have access to everything after onboarding
            if (isOwner) {
                return true;
            }

            // Check store permissions
            const requiredPermission = RouteItems[route].permission;

            // if no permission is required (set in route-data.ts), default to true
            if (!requiredPermission) return true;

            return Boolean(
                storePermissions[requiredPermission]?.includes(activeStoreId),
            );
        },
        [
            isSPEmployee,
            storePermissions,
            isOwner,
            activeStoreId,
            isOnboardingComplete,
        ],
    );

    return {
        hasRoutePermission,
    };
}
