import { Link } from "react-router-dom";
import { Button } from "src/@/components/ui/button";
import { ChevronRight } from "lucide-react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
    CardFooter,
} from "src/@/components/ui/card";
import { Skeleton } from "src/@/components/ui/skeleton";
import { useTrackSegmentEvent } from "#utils/segment";
import { SegmentEvents } from "#utils/segment";

interface ChartCardProps {
    title: string;
    description?: React.ReactNode;
    footer?: React.ReactNode;
    to?: string;
    isLoading?: boolean;
    children: React.ReactNode;
}

export function ChartCard({
    title,
    description,
    footer,
    to,
    isLoading,
    children,
    ...props
}: ChartCardProps) {
    const trackEvent = useTrackSegmentEvent();

    const handleClick = () => {
        trackEvent(SegmentEvents.CLICKED_CHART_CARD, {
            cardTitle: title,
            destination: to,
        });
    };

    return (
        <Card {...props}>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <div>
                        <CardTitle className="text-sm font-medium text-muted-foreground">
                            {title}
                        </CardTitle>
                        {description && (
                            <CardDescription className="mt-2">
                                {description}
                            </CardDescription>
                        )}
                    </div>
                    {to && (
                        <Link
                            to={to}
                            className="no-underline"
                            onClick={handleClick}
                        >
                            <Button
                                variant="outline"
                                size="icon"
                                className="h-8 w-8 text-muted-foreground hover:text-muted-foreground"
                            >
                                <ChevronRight className="h-4 w-4" />
                            </Button>
                        </Link>
                    )}
                </div>
            </CardHeader>
            <CardContent>
                {isLoading ? (
                    <Skeleton className="h-[240px] w-full" />
                ) : (
                    children
                )}
            </CardContent>
            {footer && (
                <CardFooter className="flex-col items-start gap-2 text-sm">
                    {footer}
                </CardFooter>
            )}
        </Card>
    );
}
