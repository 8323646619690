import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";

import { SidebarTrigger } from "src/@/components/ui/sidebar";
import { Separator } from "src/@/components/ui/separator";
import { Breadcrumb } from "src/@/components/ui/breadcrumb";
import { Button } from "src/@/components/ui/button";
import { Routes } from "#navigation/routes";
import { ROUTE_TO_BREADCRUMB_MAP } from "#navigation/sidebar/utils";

export function NavigationHeader() {
    const location = useLocation();
    const navigate = useNavigate();
    const breadcrumbItems =
        ROUTE_TO_BREADCRUMB_MAP.get(location.pathname as Routes) || [];

    // Check if we can go back in history and the previous page was from our site
    const canGoBack =
        window.history.state?.idx > 0 &&
        document.referrer.startsWith(window.location.origin);

    return (
        <header className="flex h-16 shrink-0 items-center gap-2 sticky top-0 bg-background z-10 rounded-xl">
            <div className="flex items-center gap-2 px-4">
                {canGoBack ? (
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowLeft className="h-4 w-4" />
                    </Button>
                ) : null}
                <SidebarTrigger className="-ml-1" />
                <Separator orientation="vertical" className="mr-2 h-4" />
                <Breadcrumb items={breadcrumbItems} />
            </div>
        </header>
    );
}
